import {
  Chip,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import { useParams } from "react-router";
import { RouterLink } from "src/components/router-link";
import { Scrollbar } from "src/components/scrollbar";

export const BlogListView = (props) => {
  const {
    posts,
    onPageChange = () => { },
    count,
    onRowsPerPageChange,
    page = 0,
    rowsPerPage = 0,
  } = props;

  const params = useParams();

  return (
    <Box sx={{ position: "relative" }}>
      <Scrollbar>
        <Table sx={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Labels</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {posts?.map((post) => {
              return (
                <TableRow key={post?.id}>
                  <TableCell>{post?.id}</TableCell>
                  <TableCell>
                    <Link
                      color="text.primary"
                      component={RouterLink}
                      href={`/public/${params?.companyId}/articles/${post?.id}`}
                      sx={{
                        alignItems: "center",
                        display: "inline-flex",
                      }}
                      underline="hover"
                      gap={1}
                    >
                      {post?.name}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      {post?.article_labels?.map((label) => (
                        <Chip
                          label={label?.name}
                          sx={{ background: label?.color }}
                          key={label?.id}
                        />
                      ))}
                    </Stack>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Scrollbar>
      <TablePagination
        component="div"
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        page={page}
        rowsPerPage={rowsPerPage}
        rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
      />
    </Box>
  );
};
