import apiClient from "../../utils/request";

class IntegrationApi {
  async getProviders(params = {}) {
    const response = await apiClient.get('/company/provider/providers', {
      params,
    });
    return response;
  }

  async getReview(id) {
    const response = await apiClient.get(`/company/provider/reviews/${id}`);
    return response;
  }

  async updateReview(id, data) {
    const response = await apiClient.put(`/company/provider/reviews/${id}`, data);
    return response;
  }
}

export const integrationApi = new IntegrationApi();
