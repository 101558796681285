import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import User01Icon from "@untitled-ui/icons-react/build/esm/User01";
import { RouterLink } from "src/components/router-link";
import { format } from "date-fns";
import { paths } from "src/paths";
import { getAPIUrl } from "src/config";

export const ReminderRenderContent = (reminder) => {
  const createdAt = format(new Date(reminder?.created_at), "MMM dd, h:mm a");
  const time = format(new Date(reminder?.time?.split('.')[0]), "yyyy-MM-dd, h:mm a");
  return (
    <>
      <ListItemAvatar sx={{ mt: 0.5 }}>
        <Avatar
          src={reminder?.client?.avatar ? reminder?.client?.avatar?.includes('http') ? reminder?.client?.avatar : `${getAPIUrl()}/${reminder?.client?.avatar}` : ""}
        >
          <SvgIcon>
            <User01Icon />
          </SvgIcon>
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
                m: 0,
              }}
            >
              <Link
                color="text.primary"
                component={RouterLink}
                href={`${paths.dashboard.customers.index}/${reminder?.client?.id}`}
                sx={{
                  alignItems: "center",
                  display: "inline-flex",
                }}
                underline="hover"
              >
                <Typography sx={{ mr: 0.5 }}
                  variant="subtitle2">
                  {reminder?.client?.first_name} {reminder?.client?.last_name}
                </Typography>
              </Link>

              <Typography sx={{ mr: 0.5 }}
                variant="body2">
                reminder at
              </Typography>
              <Link
                href="#"
                underline="always"
                variant="body2">
                {time}
              </Link>
            </Box>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexWrap: "wrap",
                m: 0,
              }}
            >
              <Typography
                variant="subtitle2"
                noWrap>{reminder?.description}</Typography>
            </Box>
          </>
        }
        secondary={
          <Typography
            color="text.secondary"
            variant="caption">
            {createdAt}
          </Typography>
        }
        sx={{ my: 0 }}
      />
    </>
  );
};


