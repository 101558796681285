import { styled } from '@mui/material/styles';

export const LandingChatContainer = styled('div',
  { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      overflow: 'hidden',
      [theme.breakpoints.up('md')]: {
        marginLeft: -380
      },
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      ...(open && {
        [theme.breakpoints.up('md')]: {
          marginLeft: 0
        },
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        })
      })
    }));
