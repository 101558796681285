import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SvgIcon from "@mui/material/SvgIcon";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";

import { Seo } from "src/components/seo";
import { LandingBrand } from "src/sections/landing/lead-management/landing-brand";

const Page = () => (
  <>
    <Seo title="Lead Management : Brand" />
    <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
      <Container maxWidth="xxl">
        <Stack spacing={3} sx={{ mb: 4 }}>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">Brand</Typography>
            <Button
              startIcon={<SvgIcon>
                <PlusIcon />
              </SvgIcon>}
              variant="contained"
            >
              Add
            </Button>
          </Stack>
        </Stack>
        <LandingBrand />
      </Container>
    </Box>
  </>
);

export default Page;
