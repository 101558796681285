import { useCallback, useEffect, useRef, useState } from "react";
import ChevronDownIcon from "@untitled-ui/icons-react/build/esm/ChevronDown";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SvgIcon from "@mui/material/SvgIcon";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { usePopover } from "src/hooks/use-popover";
import { Box, IconButton, TextField } from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Scrollbar } from "../scrollbar";
import { useDebounce } from "src/hooks/use-debounce";

export const RenderItem = ({
  option,
  handleValueChange,
  value,
  isExclude,
  nonValue,
  handleNonValueChange,
}) => {
  return (
    <MenuItem key={option?.label} sx={{ px: 4 }}>
      <Stack
        direction="row"
        width={1}
        alignItems="center"
        justifyContent="space-between"
        gap={1}
      >
        <FormControlLabel
          control={<Checkbox
            checked={value?.some((item) => item == option.value)}
            onChange={handleValueChange}
            value={option.value}
            sx={{ p: 0, mr: 1 }} />}
          label={<Stack direction='row' alignItems='center' gap={1}>
            {option?.color ? <Box sx={{ backgroundColor: option?.color, maxWidth: 1, height: 1, padding: 1, borderRadius: 20 }}></Box> : null}
            <Typography sx={{ whiteSpace: "nowrap", flexGrow: 1 }}>
              {option.label}
            </Typography>
          </Stack>}
          sx={{
            flexGrow: 1,
            mr: 0,
            fontSize: 14,
          }} />
        {isExclude && (
          <IconButton
            onClick={() => handleNonValueChange(option.value)}
            sx={{ p: 0 }}
          >
            <RemoveCircleOutlineIcon
              sx={{
                color: nonValue?.includes(option.value) ? "red" : "gray",
                opacity: nonValue?.includes(option.value) ? 1 : 0.5,
              }}
              fontSize="small" />
          </IconButton>
        )}
      </Stack>
    </MenuItem>
  );
};

export const RenderInput = ({ placeholder, setSearch }) => {
  const [inputValue, setInputValue] = useState("");
  const query = useDebounce(inputValue, 200);
  const handleSearch = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setInputValue(event.target.value);
  };

  useEffect(() => {
    setSearch(query);
  }, [query])

  return (
    <Stack sx={{ px: 2, py: 1 }}>
      <TextField
        type="search"
        placeholder={placeholder ?? ""}
        value={inputValue}
        onChange={handleSearch}
        hiddenLabel
        size="small"
        onKeyDown={(e) => e.stopPropagation()}
      />
    </Stack>
  )
}

export const FilterMultiSelect = ({
  label,
  options,
  placeholder,
  value = [],
  onChange,
  withSearch,
  isExclude,
  isLabel,
  handleModalOpen,
  valueNon = [],
  onChangeNon,
  onGetOptions,
}) => {
  const [values, setValues] = useState(options ?? []);
  const prevOptions = useRef();

  const [search, setSearch] = useState("");
  const query = useDebounce(search, 100);

  const popover = usePopover();

  useEffect(() => {
    if (onGetOptions) {
      onGetOptions(query);
    }
  }, [query]);

  useEffect(() => {
    if (JSON.stringify(prevOptions.current) !== JSON.stringify(options)) {
      setValues(options);
      prevOptions.current = options;
    }
  }, [options]);

  useEffect(() => {
    if (onGetOptions) return;
    const filteredValues = options.filter((val) =>
      val?.label?.toLowerCase()?.includes(search?.toLowerCase())
    );
    if (search) {
      setValues(filteredValues);
    } else {
      setValues(options);
    }
  }, [search, options]);

  const handleValueChange = useCallback(
    (event) => {
      let newValue = [...value];

      if (event.target.checked) {
        newValue.push(event.target.value);
      } else {
        newValue = newValue.filter((item) => item !== event.target.value);
      }

      onChange?.(newValue);
    },
    [onChange, value]
  );

  const handleNonValueChange = (val) => {
    let newValue = [...valueNon];
    if (!newValue.includes(val)) {
      newValue.push(val);
    } else {
      newValue = newValue.filter((item) => item !== val);
    }
    onChangeNon?.(newValue);
  };

  return (
    <>
      <Button
        color="inherit"
        endIcon={
          <SvgIcon>
            <ChevronDownIcon />
          </SvgIcon>
        }
        sx={{ p: 0 }}
        onClick={popover.handleOpen}
        ref={popover.anchorRef}
      >
        <Typography fontSize={14} fontWeight="600" sx={{ whiteSpace: 'nowrap' }}>
          {label}
        </Typography>
      </Button>
      <Menu
        anchorEl={popover.anchorRef.current}
        onClose={popover.handleClose}
        open={popover.open}
        PaperProps={{ style: { px: 2 } }}
      >
        {withSearch && (
          <RenderInput placeholder={placeholder} setSearch={setSearch} />
        )}
        <Scrollbar style={{ maxHeight: 180, minWidth: 150 }}>
          {values.map((option) => (
            <RenderItem
              option={option}
              key={option.label}
              handleValueChange={handleValueChange}
              value={value}
              handleNonValueChange={handleNonValueChange}
              nonValue={valueNon}
              isExclude={isExclude}
            />
          ))}
        </Scrollbar>
        {isLabel && (
          <Stack>
            <Button onClick={() => handleModalOpen()}>Edit Labels</Button>
          </Stack>
        )}
      </Menu>
    </>
  );
};
