import { Card, CardContent, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { QuillEditor } from "src/components/quill-editor";

export const LandingCustomerNote = () => (
  <Stack spacing={4}>
    <Card>
      <CardContent>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={4}>
          <Stack spacing={1}>
            <Typography variant="h5">Customer Note</Typography>
          </Stack>
        </Stack>
        <QuillEditor
          placeholder="Write something"
          sx={{ height: 350, my: 3 }} />
      </CardContent>
    </Card>
  </Stack>
);
