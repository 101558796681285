import { Stack } from "@mui/system";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

import { Seo } from "src/components/seo";
import { LandingWalletsList } from "src/sections/landing/wallets/wallets-list";
import { WalletBalance } from "src/sections/landing/wallets/balance";

const Page = () => {
  return (
    <>
      <Seo title="Wallets" />
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth="lg">
          <Stack spacing={3} sx={{ mb: 3 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">Wallet</Typography>
            </Stack>
          </Stack>
          <WalletBalance />
          <LandingWalletsList />
        </Container>
      </Box>
    </>
  );
};

export default Page;
