import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";

import { Seo } from 'src/components/seo';
import { usePageView } from 'src/hooks/use-page-view';
import { LandingDataEntryTable } from 'src/sections/landing/payment-audit/data-entry/data-entry-table';

const Page = () => {
  usePageView();
  return (
    <>
      <Seo title="Payment Audit : Data Entry" />
      <Box
        component="main"
        sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth="xxl">
          <Stack
            spacing={3}
            sx={{ mb: 4 }}
          >
            <Stack
              direction="row"
              justifyContent="space-between">
              <Typography variant="h4" >
                Data Entry
              </Typography>
              <Button
                startIcon={
                  <SvgIcon>
                    <PlusIcon />
                  </SvgIcon>
                }
                variant="contained"
              >
                Add
              </Button>
            </Stack>
          </Stack>
          <LandingDataEntryTable />
        </Container>
      </Box>
    </>
  );
};

export default Page;
