import { Fragment } from "react";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import useMediaQuery from "@mui/material/useMediaQuery";

import { LandingSettingLabel } from "./customer-logs-setting-label";

export const LandingLogsSidebar = (props) => {
  const {
    container,
    onClose,
    currentMenu,
    setCurrentMenu,
    open,
    ...other
  } = props;

  const mdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  const sideMenuList = [
    { label: "Email", value: "Email" },
    { label: "Phone", value: "PhoneNumber" },
    { label: "Comments", value: "ClientComment" },
    { label: "Transactions", value: "TTransaction" },
    { label: "Positions", value: "Position" },
  ];

  const content = (
    <div>
      <Stack
        alignItems="center"
        direction="row"
        spacing={2}
        sx={{
          pt: 2,
          px: 2,
        }}
      >
        <Stack direction="row" width={1} justifyContent="end">
          {!mdUp && (
            <IconButton onClick={onClose}>
              <SvgIcon>
                <XIcon />
              </SvgIcon>
            </IconButton>
          )}
        </Stack>
      </Stack>
      <Box
        sx={{
          pb: 2,
          px: 2,
          pt: 2,
        }}
      >
        <Stack direction="column" mt={2} gap={1}>
          {sideMenuList.map((item) => (
            <Fragment key={item?.value}>
              <List disablePadding>
                <LandingSettingLabel
                  menu={item}
                  active={currentMenu === item?.value}
                  onClick={() => setCurrentMenu(item?.value)}
                />
              </List>
            </Fragment>
          ))}
        </Stack>
      </Box>
    </div>
  );

  if (mdUp) {
    return (
      <Drawer
        anchor="left"
        open={open}
        PaperProps={{
          sx: {
            position: "relative",
            width: 280,
          },
        }}
        SlideProps={{ container }}
        variant="persistent"
        {...other}
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      hideBackdrop
      ModalProps={{
        container,
        sx: {
          pointerEvents: "none",
          position: "absolute",
        },
      }}
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          maxWidth: "100%",
          width: 280,
          pointerEvents: "auto",
          position: "absolute",
          pb: 2,
          borderRadius: 4,
          height: 'auto'
        },
      }}
      SlideProps={{ container }}
      variant="temporary"
      {...other}
    >
      {content}
    </Drawer>
  );
};
