import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { useSelector, useDispatch } from "react-redux";
import { thunks } from "../../../thunks/company";
import { thunks as customerThunk } from "src/thunks/customers";
import { useSettings } from "src/hooks/use-settings";
import { usePopover } from "src/hooks/use-popover";
import { TenantPopover } from "./tenant-popover";
import ChevronDownIcon from "@untitled-ui/icons-react/build/esm/ChevronDown";
import IconButton from "@mui/material/IconButton";
import SvgIcon from "@mui/material/SvgIcon";
import { useAuth } from "src/hooks/use-auth";
import { paths } from "src/paths";
import { useRouter } from "src/hooks/use-router";
import { TwoFactorModalCreds } from "src/pages/auth/jwt/two-factor-modal-creds";

export const TenantSwitch = ({ isTop, ...props }) => {
  const dispatch = useDispatch();
  const companyId = localStorage.getItem("company_id");
  const settings = useSettings();
  const popover = usePopover();
  const router = useRouter();
  const companyName = useSelector((state) => state.companies.name);
  const { signIn, initialize } = useAuth();

  const [openOtpModal, setOpenOtpModal] = useState(false);
  const [companyWithOtp, setCompanyWithOtp] = useState(null)

  useEffect(() => {
    dispatch(thunks.getCompany(companyId));
  }, [dispatch]);

  const companies = localStorage.getItem("tenants")
    ? JSON.parse(localStorage.getItem("tenants"))
    : null;


  const handleSwitchCompany = async (company) => {
    const { account } = company;
    if ((company?.company?.otp_enabled || account?.otp_enabled) && !company?.token) {
      setCompanyWithOtp(company?.company?.id);
      setOpenOtpModal(true);
    } else {
      await signIn(company);
      setTimeout(() => {
        initialize();
        dispatch(customerThunk.resetAll());
      }, 1000);
    }

    let redirect = '#';

    if (account?.acc?.acc_v_overview === true || undefined) {
      redirect = paths.dashboard.index;
    } else if (account?.acc?.acc_v_client === true || undefined) {
      redirect = paths.dashboard.customers.index;
    } else if (account?.acc?.acc_v_agents === true || undefined) {
      redirect = paths.dashboard.agents;
    } else if (account?.acc?.acc_v_chat === true || undefined) {
      redirect = paths.dashboard.internalChat;
    } else if (account?.acc?.acc_v_lm_leads === true || undefined) {
      redirect = paths.dashboard.lead.status.index;
    } else if (account?.acc?.acc_v_lm_aff === true || undefined) {
      redirect = paths.dashboard.lead.affiliate.index;
    } else if (account?.acc?.acc_v_lm_brand === true || undefined) {
      redirect = paths.dashboard.lead.brands.index;
    } else if (account?.acc_v_lm_list === true || undefined) {
      redirect = paths.dashboard.lead.injection.index;
    } else if (account?.acc?.acc_v_lm_offer === true || undefined) {
      redirect = paths.dashboard.lead.offers.index;
    } else if (account?.acc?.acc_v_risk_management === true || undefined) {
      redirect = paths.dashboard.risk.positions;
    } else if (account?.acc?.acc_v_logs === true || undefined) {
      redirect = paths.dashboard.log.index;
    } else if (account?.acc?.acc_v_audit_merchant === true || undefined) {
      redirect = paths.dashboard.paymentAudit.merchant.index;
    } else if (account?.acc?.acc_v_audit_bank === true || undefined) {
      redirect = paths.dashboard.paymentAudit.bankProvider.index;
    } else if (account?.acc?.acc_v_audit_payment_type === true || undefined) {
      redirect = paths.dashboard.paymentAudit.paymentType.index;
    } else if (account?.acc?.acc_v_audit_tasks === true || undefined) {
      redirect = paths.dashboard.paymentAudit.validationRules.index;
    } else if (account?.acc?.acc_v_audit_data === true || undefined) {
      redirect = paths.dashboard.paymentAudit.dataEntry.index;
    } else if (account?.acc?.acc_v_article === true || undefined) {
      redirect = paths.dashboard.article.index;
    } else if (account?.acc?.acc_v_settings === true || undefined) {
      redirect = paths.dashboard.settings;
    } else if (account?.acc?.acc_v_reports === true || undefined) {
      redirect = paths.dashboard.reports;
    } else {
      redirect = paths.dashboard.index;
    }

    if ((!company?.company?.otp_enabled && !account?.otp_enabled) || company?.otp_used === true) {
      router.push(redirect);
    }
  };

  return (
    <>
      <Stack alignItems="center" direction="row" spacing={2} {...props}>
        <Box
          sx={{
            display: {
              xs: settings?.layout === "horizontal" && isTop ? "none" : "block",
              md: "block",
            },
          }}
          gap={1}
        >
          <Typography
            color="inherit"
            sx={{
              py: 0,
            }}
          >
            {companyName}
          </Typography>
        </Box>
        {companies ? (
          <IconButton onClick={popover.handleOpen} ref={popover.anchorRef}>
            <SvgIcon sx={{ fontSize: 16 }}>
              <ChevronDownIcon />
            </SvgIcon>
          </IconButton>
        ) : null}
      </Stack>
      {companies ? (
        <TenantPopover
          anchorEl={popover.anchorRef.current}
          onChange={handleSwitchCompany}
          onClose={popover.handleClose}
          open={popover.open}
          tenants={companies}
        />
      ) : null}

      <TwoFactorModalCreds
        companyId={companyWithOtp}
        open={openOtpModal}
        onClose={() => {
          setOpenOtpModal(false);
          setCompanyWithOtp(null);
        }}
        withReload
      />
    </>
  );
};
