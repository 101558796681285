import { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { TableCell, TableRow, TextField, IconButton, Tooltip, Badge } from "@mui/material";
import { Stack } from "@mui/system";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DoneIcon from '@mui/icons-material/Done';

import { brandsApi } from "src/api/lead-management/brand";
import { DeleteModal } from "src/components/customize/delete-modal";


export const BrandStatusItem = (props) => {
  const { status, deleteStatuses, updateStatuses } = props;

  const { register, setValue, watch } = useForm();

  const [isPending, setIsPending] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const onSubmit = async (isDefault) => {
    const data = watch();
    if (isDefault) {
      delete data?.name;
    }
    setIsPending(true);
    try {
      const res = await brandsApi.updateBrandStatus({ ...data, id: status?.id });
      updateStatuses(res?.status);
      toast('Brand status successfully updated!')
    } catch (error) {
      console.error('error: ', error);
    }
    setIsPending(false);
  };

  const deleteStatus = async () => {
    setIsPending(true);
    try {
      await brandsApi.deleteBrandStatus(status?.id);
      deleteStatuses(status?.id);
      toast('Brand status successfully deleted!')
    } catch (error) {
      console.error('error: ', error);
    }
    setIsPending(false);
    setModalOpen(false);
  };

  useEffect(() => {
    setValue('name', status?.name);
  }, []);

  return (
    <>
      <TableRow key={status.id}>
        <TableCell>
          {status?.default ?
            <Badge
              variant="dot"
              color="success">
              <Tooltip
                placement="top"
                title="Default Status">
                <TextField
                  disabled={status?.default}
                  size="small"
                  hiddenLabel
                  {...register('name')}
                  sx={{ width: 120 }}
                />
              </Tooltip>
            </Badge>
            :
            <TextField
              disabled={status?.default}
              size="small"
              hiddenLabel
              {...register('name')}
              sx={{ width: 120 }}
            />
          }
        </TableCell>
        <TableCell>
          <Stack direction='row'>
            <Tooltip
              placement="top"
              title="Update">
              <IconButton
                disabled={isPending || status?.default}
                sx={{ p: 0, pr: 1 }}
                onClick={() =>
                  onSubmit(status?.default)
                }>
                <DoneIcon
                  color="success"
                />
              </IconButton>
            </Tooltip>
            <Tooltip
              placement="top"
              title="Delete">
              <IconButton
                disabled={isPending || status?.default}
                onClick={() => setModalOpen(true)}>
                <DeleteOutlineIcon
                  fontSize="medium"
                  color="error"
                  sx={(theme) => ({
                    color: status?.default ? 'gray' : theme.palette.error.main
                  })}
                />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
      <DeleteModal
        isOpen={modalOpen}
        setIsOpen={() => setModalOpen(false)}
        onDelete={() => deleteStatus()}
        title={'Delete Brand Status'}
        description={'Are you sure you want to delete this Brand Status?'}
      />
    </>
  );
};
