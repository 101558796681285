import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";

import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { PricingFaqs } from "src/sections/pricing/pricing-faqs";
import { PricingPlan } from "src/sections/pricing/pricing-plan";
import { PricingPlanIcon } from "src/sections/pricing/pricing-plan-icon";
import { useSearchParams } from "src/hooks/use-search-params";
import { useEffect } from "react";

const Page = () => {
  const searchParams = useSearchParams();
  const localRef = localStorage.getItem("ref") ?? "";
  usePageView();

  const ref = searchParams.get("ref") ?? "";

  useEffect(() => {
    if (ref) {
      localStorage.setItem("ref", ref);
    }
  }, [searchParams]);

  const linkRef = ref || localRef;

  return (
    <>
      <Seo title="Pricing" />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Box
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "dark" ? "neutral.800" : "neutral.50",
            pb: "120px",
            pt: "184px",
          }}
        >
          <Container maxWidth="lg">
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                mb: 4,
              }}
            >
              <Typography variant="h3">
                Embark on a Lead Management Revolution!
              </Typography>
              <Typography color="text.secondary" sx={{ my: 2 }} variant="body1">
                Join over 5,000+ businesses leveraging OCTOLIT to enhance their
                lead generation and customer relationships. <br />
                <p style={{ textAlign: "center", margin: 0 }}>
                  Step into the future of efficient lead management today!
                </p>
              </Typography>
            </Box>
            <Grid container spacing={4}>
              <Grid xs={12} md={4}>
                <PricingPlan
                  cta="Purchase Now"
                  currency="€"
                  description="Advanced Verification Tools and Reporting."
                  payLink={`https://buy.stripe.com/bIY3ej2PQ41g55mdQU${
                    linkRef ? `?prefilled_promo_code=${linkRef}` : ""
                  }`}
                  features={[
                    "Offer Builder",
                    "Advanced Reporting and Filtering",
                  ]}
                  icon={<PricingPlanIcon name="standard" />}
                  name="List Filtering"
                  popular
                  price="35"
                  sx={{
                    height: "100%",
                    maxWidth: 460,
                    mx: "auto",
                  }}
                />
              </Grid>
              <Grid xs={12} md={4}>
                <PricingPlan
                  cta="Purchase Now"
                  currency="€"
                  description="Advanced Verification Tools and Reporting."
                  payLink={`https://buy.stripe.com/bIY3ej2PQ41g55mdQU${
                    linkRef ? `?prefilled_promo_code=${linkRef}` : ""
                  }`}
                  features={[
                    "Data Verification",
                    "Advanced Reporting and Filtering",
                  ]}
                  icon={<PricingPlanIcon name="standard" />}
                  name="List Filtering"
                  popular
                  price="200"
                  sx={{
                    height: "100%",
                    maxWidth: 460,
                    mx: "auto",
                  }}
                />
              </Grid>
              <Grid xs={12} md={4}>
                <PricingPlan
                  cta="Purchase Now"
                  currency="€"
                  description="Full access with top-notch reporting system and exclusive Support."
                  payLink={`https://buy.stripe.com/7sI2afcqq69ocxObIO${
                    linkRef ? `?prefilled_promo_code=${linkRef}` : ""
                  }`}
                  features={[
                    "Rapid Integration",
                    "Data Verification",
                    "Advanced Reporting and Filtering",
                    "Custom Affiliates",
                    "Brand Prioritization",
                  ]}
                  icon={<PricingPlanIcon name="standard" />}
                  name="Pro (monthly)"
                  popular
                  price="800"
                  sx={{
                    height: "100%",
                    maxWidth: 460,
                    mx: "auto",
                  }}
                />
              </Grid>
            </Grid>
          </Container>
        </Box>
        <Divider />
        <PricingFaqs />
      </Box>
    </>
  );
};

export default Page;
