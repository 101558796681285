import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-hot-toast";

import { useMounted } from "src/hooks/use-mounted";
import { settingsApi } from "src/api/settings";

export const useReminders = () => {
  const isMounted = useMounted();
  const [reminders, setReminders] = useState([]);
  const [reminderToDelete, setReminderToDelete] = useState(null);
  const [reminderToEdit, setReminderToEdit] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const unread = useMemo(() => {
    return reminders?.length;
  }, [reminders]);

  const handleRemindersGet = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await settingsApi.getReminders();
      if (isMounted()) {
        setReminders(response?.reminders);
      }
    } catch (error) {
      console.error('error: ', error);
    }
    setIsLoading(false);
  }, [isMounted]);

  useEffect(() => {
    handleRemindersGet();
  }, []);

  const handleOpenDeleteReminder = useCallback((reminderId) => {
    setReminderToDelete(reminderId);
    setOpenDelete(true);
  }, []);

  const handleOpenEditReminder = useCallback((reminder) => {
    setReminderToEdit(reminder);
    setOpenEdit(true);
  }, []);

  const handleRemoveOne = useCallback(async () => {
    try {
      await settingsApi.deleteReminder(reminderToDelete);
      toast.success("Reminder successfully removed!");
      handleRemindersGet();
      setReminderToDelete(null);
      setOpenDelete(false);
    } catch (error) {
      setOpenDelete(false);
      setReminderToDelete(null);
      toast.error(error?.response?.data?.message);
    }
  }, [handleRemindersGet, reminderToDelete]);

  const handleUpdateOne = useCallback(
    async (data) => {
      try {
        await settingsApi.updateReminder(reminderToEdit?.id, {
          ...data,
          ticket_id: reminderToEdit?.internal_ticket_id,
          client_id: reminderToEdit?.client_id,
        });
        toast.success("Reminder successfully updated!");
        handleRemindersGet();
        setReminderToEdit(null);
        setOpenEdit(false);
      } catch (error) {
        setOpenEdit(false);
        setReminderToEdit(null);
        toast.error(error?.response?.data?.message);
      }
    },
    [reminderToEdit]
  );

  const handleMarkAllAsRead = useCallback(() => {
    setReminders((prevState) => {
      return prevState.map((notification) => ({
        ...notification,
        read: true,
      }));
    });
  }, []);

  return {
    handleMarkAllAsRead,
    handleRemoveOne,
    openDelete,
    openEdit,
    setOpenEdit,
    setOpenDelete,
    handleOpenDeleteReminder,
    handleOpenEditReminder,
    reminders,
    unread,
    reminderToEdit,
    handleUpdateOne,
    handleRemindersGet,
    isLoading,
  };
};
