import { Button, Card, CardContent, CardHeader, Stack } from "@mui/material";
import { useState } from "react";

import { DeleteModal } from "src/components/customize/delete-modal";
import ReactLoading from 'react-loading';

export const InjectionManagement = ({ onDeleteInjection, isLoading }) => {
  const [openModal, setModalOpen] = useState(false);

  const handleDelete = () => {
    setModalOpen(false);
    onDeleteInjection();
  }

  return (
    <Card sx={{ mt: 4 }}>
      <CardHeader title="Injection Management" />
      <CardContent>
        <Stack alignItems="flex-end">
          {isLoading ? (
            <Stack sx={{ px: "18px", py: "1px" }}>
              <ReactLoading
                type="bars"
                width={24}
                height={21}
                color={"white"}
              />
            </Stack>
          ) : (
            <Button
              color="error"
              variant="outlined"
              sx={{ maxWidth: 150 }}
              onClick={() => setModalOpen(true)}
            >
              Delete Injection
            </Button>
          )}
        </Stack>
      </CardContent>

      <DeleteModal
        isOpen={openModal}
        setIsOpen={() => setModalOpen(false)}
        onDelete={handleDelete}
        title={"Delete Injection"}
        description={"Are you sure you want to delete this Injection?"}
      />
    </Card>
  );
};
