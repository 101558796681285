import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardHeader from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import ReactCountryFlag from "react-country-flag";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";

import { TableSkeleton } from "../../../../components/table-skeleton";
import { countries } from "../../../../utils/constant";
import { Scrollbar } from "../../../../components/scrollbar";
import { useAuth } from "src/hooks/use-auth";

export const AnalyticsByCounty = ({
  data,
  onClick,
  isLoading,
  isPublic = false,
}) => {
  const { user } = useAuth();
  return (
    <Card>
      <CardHeader title="Country Count" />
      <Scrollbar>
        <Table>
          <TableHead>
            <TableRow>
              {["Country", "Total Leads", "Verified Leads"]?.map((item) => (
                <TableCell key={item} sx={{ whiteSpace: "nowrap" }}>
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableSkeleton rowCount={5} cellCount={3} />
            ) : (
              data?.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Stack direction="row" gap={1} alignItems="center">
                      <ReactCountryFlag
                        countryCode={item?.country}
                        svg
                        style={{ height: "20px", width: "25px" }}
                      />
                      <Typography variant="subtitle2" noWrap>
                        {
                          countries?.find(
                            (country) => country?.code === item?.country
                          )?.label
                        }
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell>{item?.total_leads}</TableCell>
                  <TableCell>{item?.verified_leads}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </Scrollbar>
      {!isLoading && !data?.length && (
        <Box
          sx={{
            py: 5,
            maxWidth: 1,
            alignItems: "center",
            display: "flex",
            flexGrow: 1,
            flexDirection: "column",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Box
            component="img"
            src="/assets/errors/error-404.png"
            sx={{
              height: "auto",
              maxWidth: 120,
            }}
          />
          <Typography color="text.secondary" sx={{ mt: 2 }} variant="subtitle1">
            No Data.
          </Typography>
        </Box>
      )}
      <Divider />
      {isPublic || (user?.acc?.acc_v_lm_leads !== undefined && !user?.acc?.acc_v_lm_leads) ? null : (
        <CardActions>
          <Button
            color="inherit"
            endIcon={
              <SvgIcon>
                <ArrowRightIcon />
              </SvgIcon>
            }
            onClick={() => onClick()}
            size="small"
          >
            See more
          </Button>
        </CardActions>
      )}
    </Card>
  );
};
