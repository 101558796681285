import { Helmet } from 'react-helmet-async';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

function extractDomain(url) {
  const regex = /^https?:\/\/crm\.([^.]+)\.[^.]+$/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

export const Seo = (props) => {
  const { title } = props;

  const companyName = useMemo(() => {
    return extractDomain(window?.location?.href);
  }, []);

  const fullTitle = title
    ? title + `${companyName ? ' | ' + companyName : ' | Octolit'}`
    : `${companyName ? companyName : 'Octolit'}`;

  return (
    <Helmet>
      <title>
        {fullTitle}
      </title>
    </Helmet>
  );
};

Seo.propTypes = {
  title: PropTypes.string
};
