import {
  Button,
  Card,
  CardContent,
  SvgIcon,
  Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import PropTypes from "prop-types";

import PhoneIcon from "@untitled-ui/icons-react/build/esm/Phone";
import { useTwilio } from "src/hooks/use-twilio";

export const IncomingCallPopup = () => {
  const { answerCall, declineCall } = useTwilio();

  return (
    <Card
      elevation={16}
      sx={{
        position: "fixed",
        top: 67,
        left: "40%",
        width: 600,
      }}
    >
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Stack spacing={2} direction="row" alignItems="center">
            <SvgIcon sx={{ color: "green" }}>
              <PhoneIcon />
            </SvgIcon>
            <Typography variant="h6">Incoming call...</Typography>
          </Stack>

          <Stack direction="row" spacing={2}>
            <Button onClick={answerCall} variant="contained" sx={{ backgroundColor: "green", width: 100 }}>
              Answer
            </Button>
            <Button onClick={declineCall} variant="contained" sx={{ backgroundColor: "red", width: 100 }}>
              Decline
            </Button>
          </Stack>
        </Stack>
      </CardContent>
    </Card>
  );
};

IncomingCallPopup.propTypes = {
  conversationId: PropTypes.string.isRequired,
};
