import { useEffect } from "react";
import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { DeleteModal } from "src/components/customize/delete-modal";
import { EditReminderModal } from "./reminder-edit";
import { RemindersPopover } from "./reminders-popover";
import { usePopover } from "src/hooks/use-popover";
import { useReminders } from "src/hooks/overview/use-reminders";
import Iconify from 'src/components/iconify';

export const RemindersButton = () => {
  const popover = usePopover();
  const {
    handleRemoveOne,
    handleMarkAllAsRead,
    reminders,
    unread,
    openDelete,
    openEdit,
    setOpenEdit,
    setOpenDelete,
    handleOpenDeleteReminder,
    handleOpenEditReminder,
    reminderToEdit,
    handleUpdateOne,
    handleRemindersGet,
  } = useReminders();

  useEffect(() => {
    if (popover.open) handleRemindersGet();
  }, [popover.open]);

  return (
    <>
      <Tooltip title="Reminders">
        <IconButton
          ref={popover.anchorRef}
          onClick={popover.handleOpen}
          sx={{ '&:hover': { color: 'primary.main' }}}
          >
          <Badge
            color="error"
            badgeContent={unread}>
            <Iconify icon="lucide:calendar" width={26}
          />
          </Badge>
        </IconButton>
      </Tooltip>
      <RemindersPopover
        anchorEl={popover.anchorRef.current}
        reminders={reminders}
        onClose={popover.handleClose}
        onMarkAllAsRead={handleMarkAllAsRead}
        onRemoveOne={handleOpenDeleteReminder}
        onEditOne={handleOpenEditReminder}
        open={popover.open}
      />
      <DeleteModal
        isOpen={openDelete}
        setIsOpen={setOpenDelete}
        title="Are you sure you want to delete reminder?"
        onDelete={handleRemoveOne}
      />
      <EditReminderModal
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        reminder={reminderToEdit}
        onUpdateReminder={handleUpdateOne}
      />
    </>
  );
};
