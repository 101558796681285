import { createAsyncThunk } from "@reduxjs/toolkit";
import { internalChatApi } from "../api/internal-chat";

export const getInternalChat = createAsyncThunk('getInternalChat', async (data) => {
  const accountId = localStorage.getItem("account_id");
  const response = await internalChatApi.getInternalChats(data);
  return response?.conversations?.filter(c => c?.public || c?.account_ids?.includes(Number(accountId)));
})

export const thunks = {
  getInternalChat,
};

