import LandingCustomerBalance from "./customer-balance";
import { LandingCustomerPositionsTable } from "./customer-positions-table";

export const LandingCustomerPosition = () => {
  return (
    <>
      <LandingCustomerBalance />
      <LandingCustomerPositionsTable />
    </>
  );
};
