import { slice } from "src/slices/client_chat";
import { customersApi } from "../api/customers";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getClientChat = createAsyncThunk('tickets', async (data) => {
  const response = await customersApi.getCustomerPrevTickets(data);
  return response?.tickets;
})

const resetChat = () => (dispatch) => {
  dispatch(slice.actions.resetClientChat());
};

export const thunks = {
  getClientChat,
  resetChat,
};
