import ArrowRightIcon from "@untitled-ui/icons-react/build/esm/ArrowRight";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import { Controller, useForm } from "react-hook-form";

import { QuillEditor } from "src/components/quill-editor";

export const StatusDescriptionStep = (props) => {
  const { onBack, onNext, createProcessStarted, ...other } = props;

  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    onNext(data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3} {...other}>
        <Box>
          <Typography variant="h6">Add some description</Typography>
        </Box>
        <Controller
          name="description"
          control={control}
          render={({ field: { onChange, value } }) => (
            <QuillEditor
              placeholder="Write something"
              sx={{ height: 300 }}
              value={value}
              onChange={onChange}
            />
          )}
        />
        <Stack alignItems="center" direction="row" spacing={2}>
          <Button
            endIcon={!createProcessStarted ? (
              <SvgIcon>
                <ArrowRightIcon />
              </SvgIcon>
            ) : null}
            type='submit'
            variant="contained"
          >
            {!createProcessStarted ?
              <Typography variant='subtitle2'>Finish</Typography> :
              <Stack sx={{ px: '20px' }}>
                <ReactLoading
                  type='bars'
                  width={24}
                  height={21}
                  color={"white"}
                />
              </Stack>
            }
          </Button>
          <Button color="inherit" onClick={onBack}>
            Back
          </Button>
        </Stack>
      </Stack>
    </form>
  );
};

StatusDescriptionStep.propTypes = {
  onBack: PropTypes.func,
  onNext: PropTypes.func,
};
