import apiClient from 'src/utils/request';

class AffiliateApi {
  async createAffiliate(data) {
    const response = await apiClient.post(`/lead_management/affiliates`, data);
    return response;
  }

  async getAffiliates(request) {
    const response = await apiClient.get(`/lead_management/affiliates`, { params: request });
    return response;
  }

  async getAffiliate(id) {
    const response = await apiClient.get(`/lead_management/affiliates/${id}`);
    return response;
  }

  async updateAffiliate(id, data) {
    const response = await apiClient.put(`/lead_management/affiliates/${id}`, data);
    return response;
  }

  async deleteAffiliate(id) {
    const response = await apiClient.delete(`/lead_management/affiliates/${id}`);
    return response;
  }

  async refreshAffiliatePassword(id) {
    const response = await apiClient.put(`lead_management/affiliate_passwords/${id}`);
    return response;
  }
}

export const affiliateApi = new AffiliateApi();
