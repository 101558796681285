import { Divider, Stack } from "@mui/material";

import { Topics } from "src/sections/dashboard/chat/voice-message/topics/topics";
import { Transcription } from "src/sections/dashboard/chat/voice-message/transcription/transcription";
import { SentimentChart } from "src/sections/dashboard/chat/voice-message/chart/sentiment-chart";
import { CallSummary } from "src/sections/dashboard/chat/voice-message/summary";
import { Compliance } from "src/sections/dashboard/chat/voice-message/compliance";

export const getSystemMessage = (info, message) => {
  const { type, systemEventAccount, account } = info;

  switch (type) {
    case 1:
      return `${account?.first_name} ${account?.last_name} has invited ${systemEventAccount?.first_name} ${systemEventAccount?.last_name} to the chat.`;
    case 2:
      return `${account?.first_name} ${account?.last_name} has removed ${systemEventAccount?.first_name} ${systemEventAccount?.last_name} from the chat.`;
    case 3:
      return `Chat name has changed`;
    case 6:
      return (
        <Stack spacing={4} py={2}>
          <SentimentChart message={message}/>
          <Divider />
          <Transcription message={message} />
          <Divider />
          <Topics message={message} />
          {message?.call_summery ?
            <>
              <Divider />
              <CallSummary message={message} />
            </>
          : null
          }
          {message?.flagged_transcript?
            <>
              <Divider />
              <Compliance message={message} />
            </>
          : null
          }
        </Stack>
      )
    default:
      return message?.description;
  }
};
