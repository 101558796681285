import PropTypes from "prop-types";

import { withAuthGuard } from "src/hocs/with-auth-guard";
import { useSettings } from "src/hooks/use-settings";

import { useAffiliateSections, useSections } from "./config";
import { HorizontalLayout } from "./horizontal-layout";
import { VerticalLayout } from "./vertical-layout";
import { useAuth } from "src/hooks/use-auth";
import { useMemo } from "react";

export const Layout = withAuthGuard((props) => {
  const settings = useSettings();
  const sections = useSections();
  const affiliateSections = useAffiliateSections();
  const { user } = useAuth();

  const affiliateSectionsWithAccess = useMemo(() => {
    if (user) {
      const filteredData = affiliateSections?.map((item) => {
        const filteredItems = item.items.filter((subItem) => {
          if (subItem.title === "Customers") {
            return user?.acc?.acc_v_client === undefined && !user?.affiliate ? true : user?.acc?.acc_v_client && !user?.affiliate;
          } else if (subItem.title === "Agents") {
            return user?.acc?.acc_v_agents;
          } else if (subItem.title === "Internal Chat") {
            return false;
          } else if (subItem.title === "Support Chats") {
            return user?.acc?.acc_v_support_chats === undefined ? true : user?.acc?.acc_v_support_chats;
          } else if (subItem.title === "Lead Management") {
            return user?.acc?.acc_v_lead_management === undefined ? true : user?.acc?.acc_v_lead_management;
          } else if (subItem.title === "Risk Management") {
            return user?.acc?.acc_v_risk_management === undefined && !user?.affiliate ? true : user?.acc?.acc_v_risk_management && !user?.affiliate;
          } else if (subItem.title === "Payment Audit") {
            return false;
          } else if (subItem.title === "Article") {
            return false;
          } else if (subItem.title === "Settings") {
            return false;
          } else if (subItem.title === "Overview") {
            return false;
          } else if (subItem.title === "Leads") {
            return user?.aff_acc_leads;
          } else if (subItem.title === "Affiliates") {
            return user?.aff_acc_affiliates;
          } else if (subItem.title === "Brands") {
            return user?.aff_acc_brands;
          } else if (subItem.title === "List Injection") {
            return user?.aff_acc_inject;
          } else if (subItem.title === "Offers") {
            return user?.aff_acc_offers;
          } else if (subItem.title === "Wallets") {
            return false;
          } else if (subItem.title === "Reports") {
            return false;
          } else if (subItem.title === "Logs") {
            return false;
          } else if (subItem.title === "Calendar") {
            return user?.acc?.acc_v_calendar === undefined ? true : user?.acc?.acc_v_calendar;
          } else if (subItem.title === "Analytics") {
            return false;
          } else if (subItem.title === "FAQ & Articles") {
            return false;
          } else if (subItem.title === "Leaderboard") {
            return user?.acc?.acc_v_leaderboard === undefined ? true : user?.acc?.acc_v_leaderboard;
          }

          return true; // Keep other items
        });

        return {
          items: filteredItems,
        };
      });

      return filteredData;
    }
  }, [sections, user]);

  const sectionsWithAccess = useMemo(() => {
    if (user) {
      const filteredData = sections?.map((item) => {
        const filteredItems = item.items.filter((subItem) => {
          if (subItem.title === "Lead Management") {
            subItem.items = subItem?.items?.filter(subSubItem => {
              if (subSubItem.title === "Leads") {
                return user?.acc?.acc_v_lm_leads === undefined ? true : user?.acc?.acc_v_lm_leads;
              } else if (subSubItem.title === "Affiliates") {
                return user?.acc?.acc_v_lm_aff === undefined ? true : user?.acc?.acc_v_lm_aff;
              } else if (subSubItem.title === "Brands") {
                return user?.acc?.acc_v_lm_brand === undefined ? true : user?.acc?.acc_v_lm_brand;
              } else if (subSubItem.title === "List Injection") {
                return user?.acc?.acc_v_lm_list === undefined ? true : user?.acc?.acc_v_lm_list;
              } else if (subSubItem.title === "Offers") {
                return user?.acc?.acc_v_lm_offer === undefined ? true : user?.acc?.acc_v_lm_offer;
              }
              return true; // Keep other items
            })
          }

          if (subItem.title === "Risk Management") {
            subItem.items = subItem?.items?.filter(subSubItem => {
              if (subSubItem.title === "Positions") {
                return user?.acc?.acc_v_risk_position === undefined ? true : user?.acc?.acc_v_risk_position;
              } else if (subSubItem.title === "Transactions") {
                return user?.acc?.acc_v_risk_transactions === undefined ? true : user?.acc?.acc_v_risk_transactions;
              } else if (subSubItem.title === "Wallet Transactions") {
                return user?.acc?.acc_v_risk_wallet_transactions === undefined ? false : user?.acc?.acc_v_risk_wallet_transactions;
              }
              return true; // Keep other items
            })
          }

          if (subItem.title === "Payment Audit") {
            subItem.items = subItem?.items?.filter(subSubItem => {
              if (subSubItem.title === "Merchant Fee & Rates") {
                return user?.acc?.acc_v_audit_merchant === undefined ? true : user?.acc?.acc_v_audit_merchant;
              } else if (subSubItem.title === "Bank Provider") {
                return user?.acc?.acc_v_audit_bank === undefined ? true : user?.acc?.acc_v_audit_bank;
              } else if (subSubItem.title === "Payment Type") {
                return user?.acc?.acc_v_audit_payment_type === undefined ? true : user?.acc?.acc_v_audit_payment_type;
              } else if (subSubItem.title === "Validation Task") {
                return user?.acc?.acc_v_audit_tasks === undefined ? true : user?.acc?.acc_v_audit_tasks;
              } else if (subSubItem.title === "Data Entry") {
                return user?.acc?.acc_v_audit_data === undefined ? true : user?.acc?.acc_v_audit_data;
              }
              return true; // Keep other items
            })
          }

          if (subItem.title === "Customers") {
            return user?.acc?.acc_v_client === undefined ? true : user?.acc?.acc_v_client;
          } else if (subItem.title === "Agents") {
            return user?.acc?.acc_v_agents === undefined ? true : user?.acc?.acc_v_agents;
          } else if (subItem.title === "Internal Chat") {
            return user?.acc?.acc_v_chat === undefined ? true : user?.acc?.acc_v_chat;
          } else if (subItem.title === "Support Chats") {
            return user?.acc?.acc_v_support_chats === undefined ? true : user?.acc?.acc_v_support_chats;
          } else if (subItem.title === "Lead Management") {
            return user?.acc?.acc_v_lead_management === undefined ? true : user?.acc?.acc_v_lead_management;
          } else if (subItem.title === "Risk Management") {
            return user?.acc?.acc_v_risk_management === undefined ? true : user?.acc?.acc_v_risk_management;
          } else if (subItem.title === "Payment Audit") {
            return user?.acc?.acc_v_payment_audit === undefined ? true : user?.acc?.acc_v_payment_audit;
          } else if (subItem.title === "FAQ & Articles") {
            return user?.acc?.acc_v_article === undefined ? true : user?.acc?.acc_v_article;
          } else if (subItem.title === "Settings") {
            return user?.acc?.acc_v_settings === undefined && !user?.affiliate ? true : user?.acc?.acc_v_settings && !user?.affiliate;
          } else if (subItem.title === "Overview") {
            return user?.acc?.acc_v_overview === undefined ? true : user?.acc?.acc_v_overview;
          } else if (subItem.title === "Wallets") {
            return user?.acc?.acc_v_wallet === undefined ? true : user?.acc?.acc_v_wallet;
          } else if (subItem.title === "Reports") {
            return user?.acc?.acc_v_reports === undefined ? true : user?.acc?.acc_v_reports;
          } else if (subItem.title === "Logs") {
            return user?.acc?.acc_v_logs === undefined ? false : user?.acc?.acc_v_logs;
          } else if (subItem.title === "Calendar") {
            return user?.acc?.acc_v_calendar === undefined ? true : user?.acc?.acc_v_calendar;
          } else if (subItem.title === "Leaderboard") {
            return user?.acc?.acc_v_leaderboard === undefined ? true : user?.acc?.acc_v_leaderboard
          }
 
          return true; // Keep other items
        });

        return {
          items: filteredItems,
        };
      });

      return filteredData;
    }
  }, [sections, user]);

  if (!sectionsWithAccess || !affiliateSectionsWithAccess || !user) return null;

  if (settings.layout === "horizontal") {
    return (
      <HorizontalLayout
        sections={
          user?.affiliate ? affiliateSectionsWithAccess : sectionsWithAccess
        }
        navColor={settings.navColor}
        {...props}
      />
    );
  }

  return (
    <VerticalLayout
      sections={
        user?.affiliate ? affiliateSectionsWithAccess : sectionsWithAccess
      }
      navColor={settings.navColor}
      {...props}
    />
  );
});

Layout.propTypes = {
  children: PropTypes.node,
};
