import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CopyIcon from "@untitled-ui/icons-react/build/esm/Copy01";
import Stack from "@mui/material/Stack";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";

import { Seo } from "src/components/seo";
import { usePageView } from "src/hooks/use-page-view";
import { LandingOffersTable } from "src/sections/landing/lead-management/offers/offers-table";

const Page = () => {
  usePageView();
  return (
    <>
      <Seo title="Lead Management : Offers" />
      <Box component="main" sx={{ flexGrow: 1, py: 8 }}>
        <Container maxWidth="xxl">
          <Stack spacing={3} sx={{ mb: 1 }}>
            <Stack direction="row" justifyContent="space-between">
              <Typography variant="h4">Offers</Typography>
              <Button
                variant="outlined"
                endIcon={
                  <SvgIcon>
                    <CopyIcon />
                  </SvgIcon>
                }
              >
                Copy link
              </Button>
            </Stack>
            <Box />
          </Stack>
          <LandingOffersTable />
        </Container>
      </Box>
    </>
  );
};

export default Page;
