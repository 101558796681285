import { tokens } from "../tokens";

export const en = {
  [tokens.common.languageChanged]: "Language changed",
  [tokens.nav.aboutUs]: "About Us",
  [tokens.nav.academy]: "Academy",
  [tokens.nav.account]: "Account",
  [tokens.nav.affiliate]: "Affiliates",
  [tokens.nav.agents]: "Agents",
  [tokens.nav.analytics]: "Analytics",
  [tokens.nav.article]: "FAQ & Articles",
  [tokens.nav.supportChats]: "Support Chats",
  [tokens.nav.auth]: "Auth",
  [tokens.nav.bankProvider]: "Bank Provider",
  [tokens.nav.blog]: "Blog",
  [tokens.nav.brands]: "Brands",
  [tokens.nav.browse]: "Browse",
  [tokens.nav.calendar]: "Calendar",
  [tokens.nav.logs]: "Logs",
  [tokens.nav.call]: "Call System",
  [tokens.nav.chat]: "Chat",
  [tokens.nav.checkout]: "Checkout",
  [tokens.nav.concepts]: "Concepts",
  [tokens.nav.contact]: "Contact",
  [tokens.nav.contactUs]: "Contact Us",
  [tokens.nav.orderNow]: "Order Now",
  [tokens.nav.course]: "Course",
  [tokens.nav.create]: "Create",
  [tokens.nav.crypto]: "Crypto",
  [tokens.nav.customerCare]: "Customer Care",
  [tokens.nav.customers]: "Customers",
  [tokens.nav.dashboard]: "Dashboard",
  [tokens.nav.dataEntry]: "Data Entry",
  [tokens.nav.demo]: "DEMO",
  [tokens.nav.details]: "Details",
  [tokens.nav.ecommerce]: "E-Commerce",
  [tokens.nav.edit]: "Edit",
  [tokens.nav.error]: "Error",
  [tokens.nav.feed]: "Feed",
  [tokens.nav.fileManager]: "File Manager",
  [tokens.nav.finance]: "Finance",
  [tokens.nav.fleet]: "Fleet",
  [tokens.nav.forgotPassword]: "Forgot Password",
  [tokens.nav.home]: "Home",
  [tokens.nav.injection]: "List Injection",
  [tokens.nav.integration]: "Integration",
  [tokens.nav.internalChat]: "Internal Chat",
  [tokens.nav.invoiceList]: "Invoices",
  [tokens.nav.jobList]: "Job Listings",
  [tokens.nav.kanban]: "Kanban",
  [tokens.nav.lawFirms]: "Law Firms",
  [tokens.nav.lead]: "Lead Management",
  [tokens.nav.leads]: "Leads",
  [tokens.nav.list]: "List",
  [tokens.nav.login]: "Login",
  [tokens.nav.logistics]: "Logistics",
  [tokens.nav.mail]: "Mail",
  [tokens.nav.management]: "Management",
  [tokens.nav.marketing]: "Marketing Agencies",
  [tokens.nav.merchant]: "Merchant Fee & Rates",
  [tokens.nav.offers]: "Offers",
  [tokens.nav.orderList]: "Orders",
  [tokens.nav.overview]: "Overview",
  [tokens.nav.pages]: "Pages",
  [tokens.nav.paymentService]: "Payment Service Providers",
  [tokens.nav.paymentType]: "Payment Type",
  [tokens.nav.payment]: "Payment Audit",
  [tokens.nav.positions]: "Positions",
  [tokens.nav.postCreate]: "Post Create",
  [tokens.nav.postDetails]: "Post Details",
  [tokens.nav.postList]: "Post List",
  [tokens.nav.pricing]: "Pricing",
  [tokens.nav.productList]: "Products",
  [tokens.nav.products]: "Products",
  [tokens.nav.profile]: "Profile",
  [tokens.nav.record]: "Records",
  [tokens.nav.register]: "Register",
  [tokens.nav.reports]: "Reports",
  [tokens.nav.resetPassword]: "Reset Password",
  [tokens.nav.risk]: "Risk Management",
  [tokens.nav.leaderboard]: "Leaderboard",
  [tokens.nav.settings]: "Settings",
  [tokens.nav.socialMedia]: "Social Media",
  [tokens.nav.title]: "Title",
  [tokens.nav.transactions]: "Transactions",
  [tokens.nav.walletTransactions]: "Wallet Transactions",
  [tokens.nav.validationRules]: "Validation Task",
  [tokens.nav.verifyCode]: "Verify Code",
  [tokens.nav.wallets]: "Wallets",
};
